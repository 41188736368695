<script>
  import { Money3Component } from 'v-money3';
  import CKEditor from "@ckeditor/ckeditor5-vue";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import Swal from "sweetalert2";
  import DropZone from "@/components/widgets/dropZone";
  import Layout from "@/layouts/main.vue";
  import appConfig from "@/../app.config";
  import PageHeader from "@/components/page-header";
  import CustomField from "@/components/custom-field";
  import { viewEntity, updateEntity, saveEntity, callUrl } from '@/helpers/api-apolo';
  import { formatPrice, saError, saSuccess } from '@/helpers/global-functions';
  import * as helpers from '@/helpers/helper-functions';
  import { overlay } from "@/state/helpers";

  export default {
    page: {
      title: "Criação/Edição de Produto",
      meta: [{
        name: "description",
        content: appConfig.description,
      }],
    },
    data() {
      return {
        title: "Criação/Edição de Produto",
        items: [
          {
            text: "Crm",
            href: "/",
          },
          {
            text: "Produtos",
            href: "/apps/crm-products",
          },
          {
            text: "Criação/Edição de Produto",
            active: true,
          },
        ],
        value1: ["Scheduled"],
        value2: ["Hidden"],
        value3: ["Fashion"],
        editor: ClassicEditor,
        editorData: "<p>Tommy Hilfiger men striped pink sweatshirt. Crafted with cotton. Material composition is 100% organic cotton. This is one of the world’s leading designer lifestyle brands and is internationally recognized for celebrating the essence of classic American cool style, featuring preppy with a twist designs.</p><ul><li>Full Sleeve</li><li>Cotton</li><li>All Sizes available</li><li>4 Different Color</li></ul>",
        content: "<h1>Some initial content</h1>",
        moneyConfig: {
          masked: false,
          prefix: 'R$ ',
          suffix: '',
          thousands: '.',
          decimal: ',',
          precision: 2,
          disableNegative: true,
          disabled: false,
          min: null,
          max: null,
          allowBlank: false,
          minimumNumberOfCharacters: 0,
          modelModifiers: {
            number: false
          }
        },
        additionalFields: [],
        product: {
          description: '',
          price: '',
          social_media: [],
          videos: [],
          custom_field: {}
        },
        validation: {
          name: null,
          code: null,
          price: null,
        }
      };
    },
    components: {
      DropZone,
      Layout,
      PageHeader,
      CustomField,
      money3: Money3Component,
      ckeditor: CKEditor.component
    },
    methods: {
      ...overlay,
      ...helpers,
      removeUnusedVideo() {
        this.product.videos = this.product.videos.filter(value => value.length > 0)
      },
      removeUnusedSocialMedia() {
        this.product.social_media = this.product.social_media.filter(value => value.length > 0)
      },
      validate(field) {
        if (!this.product[field] || !this.product[field].length) {
          this.validation[field] = 'Campo obrigatório';
          return;
        }
        this.validation[field] = true;
      },
      deleteRecord(ele) {
        ele.target.parentElement.parentElement.remove();
      },
      goBack() {
        this.$router.push({ path: '/apps/crm-products' });
      },
      async saveProduct() {
        const invalidElements = Object.keys(this.validation).filter(field => this.validation[field] !== true);
        if (invalidElements.length) {
          saError('Preencha todos os campos obrigatórios');
          return;
        }
        if (this.additionalFields.length) {
          for (let i=0; i<this.additionalFields.length; i++) {
            if (this.additionalFields[i].required && (!this.product.custom_field[this.additionalFields[i].id] || !this.product.custom_field[this.additionalFields[i].id].toString().length)) {
              saError(`O campo "${this.additionalFields[i].name}" é obrigatório`);
              return;
            }
          }
        }
        const allowedCustomFields = this.additionalFields.map(field => field.id);
        this.changeShowOverlay({show: true});
        const productSave = JSON.parse(JSON.stringify(this.product));
        productSave.price = parseInt(productSave.price.toLocaleString().replace(/\D/g,''));
        productSave.custom_field = Object.keys(productSave.custom_field)
          .filter((k) => productSave.custom_field[k].toString().length && allowedCustomFields.includes(k))
          .reduce((a, k) => ({ ...a, [k]: productSave.custom_field[k] }), {});
        productSave.custom_field = Object.keys(productSave.custom_field).map(key => ({field_id: key, value: productSave.custom_field[key]}));
        let result = {};
        try {
          if (productSave.id) {
            result = await updateEntity(productSave, 'product');
          } else {
            result = await saveEntity(productSave, 'product');
          }
          if (result.id) {
            saSuccess("Produto salvo com Sucesso!");
            this.product.id = result.id;
            // this.$router.push({ path: '/apps/crm-products' });
            this.changeShowOverlay({show: false});
          }
        } catch (error) {
          this.changeShowOverlay({show: false});
          saError(error.message);
        }
      },
      addNewSocialMedia() {
        const value = document.getElementById('new-social-media-value').value;
        if (value.length) {
          this.product.social_media.push(value);
          document.getElementById('new-social-media-value').value = '';
        }
      },
      addNewVideo() {
        const value = document.getElementById('new-video-value').value;
        if (value.length) {
          this.product.videos.push({'url': value});
          document.getElementById('new-video-value').value = '';
        }
      },
      async getAdditionalFields() {
        this.changeShowOverlay({show: true});
        this.additionalFields = []
        const dataM = await callUrl({}, '/custom-field?entity=product&order=order:1', 'get')
        if (dataM && dataM.length) {
          this.additionalFields = dataM;
          for (let i=0; i < dataM.length; i++) {
            if (this.product.custom_field[dataM[i].id] === undefined) {
              this.product.custom_field[dataM[i].id] = (dataM[i].type === 'multi_enum' ? [] : '');
            }
          }
        }
        this.changeShowOverlay({show: false});
      },
      async deletePhoto(photoId) {
        Swal.fire({
          title: "Você tem certeza que deseja remover esta foto?",
          text: "Não será possível reverter esta ação!",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Sim, remover!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
            actions: 'd-flex justify-content-center',
            confirmButton: 'btn btn-success flex-grow-1',
            cancelButton: 'btn btn-outline-dark flex-grow-1'
          }
        }).then(async (result) => {
          if (result.value) {
            try {
              this.changeShowOverlay({show: true});
              await callUrl({}, '/product/' + this.product.id + '/photo/' + photoId, 'delete')
              this.changeShowOverlay({show: false});
              Swal.fire("Sucesso!", "Foto removida com sucesso", "success");
              this.product.photos = this.product.photos.filter(photo => photo.id !== photoId);
            } catch (error) {
              this.changeShowOverlay({show: false});
              saError(error.message);
            }
          }
        });
      },
      selectedFile() {
        const photoAccepted = ['image/png', 'image/gif', 'image/jpeg'];

        let files = document.querySelector(".dropzoneFile").files;
        if (files.length > 0) {
          const formDatas = [];
          for (let i = 0; i < files.length; i++) {
            if (!photoAccepted.includes(files[i].type)) {
              saError('Apenas os seguintes formatos são suportados: ' + photoAccepted.join(', '));
              return false;
            }
            let formData = new FormData();
            formData.append("file", files[i]);
            formDatas.push(formData);
          }

          this.changeShowOverlay({show: true});
          Promise.all(formDatas.map(formData => callUrl(formData, '/product/' + this.product.id + '/photo', 'post', 'formData')))
            .then(products => {
              saSuccess("Upload realizado com Sucesso!");
              for (let i = 0; i < products.length; i++) {
                for (let x = 0; x < products[i].photos.length; x++) {
                  if (!this.product.photos.filter(photo => photo.id === products[i].photos[x].id).length) {
                    this.product.photos.push(products[i].photos[x]);
                  }
                }
              }
            })
            .catch(error => {
              saError(error.message);
            })
            .finally(() => this.changeShowOverlay({show: false}));
        }
      }
    },
    async mounted() {
      const modal = document.querySelector('.modal-backdrop');
      if (modal) {
        modal.remove();
      }
      if (this.$route.params.id) {
        this.title = "Edição de Produto";
        this.items[2]['text'] = "Edição de Produto";
        const data = await viewEntity(this.$route.params.id, 'product');
        if (data) {
          data.price = formatPrice(data.price);
          if (data.custom_field && data.custom_field.length) {
            const oldCustomFields = JSON.parse(JSON.stringify(data.custom_field));
            data.custom_field = {};
            for (let i=0; i<oldCustomFields.length; i++) {
              data.custom_field[oldCustomFields[i].field_id] = oldCustomFields[i].value;
            }
          } else {
            data.custom_field = {};
          }
          this.product = data
          this.validate('name');
          this.validate('code');
          this.validate('price');
        }
      } else {
        this.title = "Criação de Produto";
        this.items[2]['text'] = "Criação de Produto";
      }
      this.getAdditionalFields();
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <form>
          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0">Informações Gerais</h5>
            </div>
            <div class="card-body row">
              <div class="col-lg-4 col-sm-12 mb-3">
                <label class="form-label" for="product-title-input">Nome *</label>
                <input type="text" :class="['form-control', {'is-valid': validation.name === true}, {'is-invalid': validation.name !== null && validation.name !== true}]" id="product-title-input" v-model="product.name" placeholder="Digite o nome do produto" @keyup="validate('name')" required />
                <div class="invalid-feedback">{{ validation.name }}</div>
              </div>
              <div class="col-lg-4 col-sm-6 mb-3">
                <label class="form-label" for="product-title-input">Código Externo *</label>
                <input type="text" :class="['form-control', {'is-valid': validation.code === true}, {'is-invalid': validation.code !== null && validation.code !== true}]" id="product-title-input" v-model="product.code" placeholder="Digite o Código do produto" @keyup="validate('code')" required />
                <div class="invalid-feedback">{{ validation.code }}</div>
              </div>
              <div class="col-lg-4 col-sm-6 mb-3">
                <label class="form-label" for="product-price-input">Preço *</label>
                <money3
                  v-model.number="product.price"
                  v-bind="moneyConfig"
                  @keyup="validate('price')"
                  id="product-price-input"
                  placeholder="Digite o preço do produto"
                  :class="['form-control', {'is-valid': validation.price === true}, {'is-invalid': validation.price !== null && validation.price !== true}]" />
                <!--input
                  type="text"
                  :class="['form-control', {'is-valid': validation.price === true}, {'is-invalid': validation.price !== null && validation.price !== true}]"
                  v-model="product.price"
                  id="product-price-input"
                  placeholder="Digite o preço sem casa decimal"
                  @keyup="validate('price')"
                  ref="inputPrice" /-->
                <div class="invalid-feedback">{{ validation.price }}</div>
              </div>
              <div>
                <label>Descrição</label>
                <ckeditor v-model="product.description" :editor="editor"></ckeditor>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0">Comunicações</h5>
            </div>
            <div class="card-body row">
              <div class="col-lg-4 col-sm-12 mb-3">
                <label class="form-label" for="product-url-input">URL</label>
                <input type="text" class="form-control" id="product-url-input" v-model="product.url" placeholder="Endereço do produto" />
                <div class="invalid-feedback">Campo Obrigatório</div>
                <div class="valid-feedback">Ok</div>
              </div>
            </div>
            <div class="card-body row">
              <label class="col-sm-12 mb-3 form-label">Mídias Sociais</label>
              <div v-for="(item, index) of product.social_media" :key="'social_media_' + index" class="col-lg-4 col-sm-12 mb-3">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="product.social_media[index]" @change="removeUnusedSocialMedia" placeholder="Link de media social do produto" />
                  <div class="invalid-feedback">Campo Obrigatório</div>
                  <div class="valid-feedback">Ok</div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-12 mb-3">
                <div class="input-group">
                  <input type="text" class="form-control" id="new-social-media-value" placeholder="Link de media social do produto" />
                  <button class="btn btn-outline-success" type="button" @click="addNewSocialMedia">Adicionar</button>
                  <div class="invalid-feedback">Campo Obrigatório</div>
                  <div class="valid-feedback">Ok</div>
                </div>
              </div>
            </div>
            <div class="card-body row">
              <label class="col-sm-12 mb-3 form-label">Vídeos</label>
              <div v-for="(item, index) of product.videos" :key="'videos_' + index" class="col-lg-4 col-sm-12 mb-3">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="product.videos[index].url" @change="removeUnusedVideo" placeholder="Link do vídeo do produto" />
                  <div class="invalid-feedback">Campo Obrigatório</div>
                  <div class="valid-feedback">Ok</div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-12 mb-3">
                <div class="input-group">
                  <input type="text" class="form-control" id="new-video-value" placeholder="Link do vídeo do produto" />
                  <button class="btn btn-outline-success" type="button" @click="addNewVideo">Adicionar</button>
                  <div class="invalid-feedback">Campo Obrigatório</div>
                  <div class="valid-feedback">Ok</div>
                </div>
              </div>
            </div>
          </div>

          <div :class="['card', {'d-none': !additionalFields.length}]">
            <div class="card-header">
              <h5 class="card-title mb-0">Campos Adicionais</h5>
            </div>
            <div class="card-body row">
              <CustomField
                v-for="(field, index) of additionalFields"
                :key="'custom_field_' + index"
                class="col-lg-6 col-sm-12 mb-3"
                :field="field"
                v-model="product.custom_field[field.id]" />
            </div>
          </div>

          <div :class="['card', {'d-none': !product.id || !product?.id?.length}]">
            <div class="card-header">
              <h5 class="card-title mb-0">Galeria do Produto</h5>
            </div>
            <div class="card-body row">
              <!--div class="mb-4">
                <h5 class="fs-14 mb-1">Imagem</h5>
                <p class="text-muted">Adicione a Imagem Principal</p>
                <input class="form-control" id="product-image-input" type="file" accept="image/png, image/gif, image/jpeg" />
              </div-->
              <div class="vstack gap-2">
                <h5 class="fs-14 mb-1">Galeria</h5>
                <p class="text-muted">Adicione as Imagens do Produto</p>
                <DropZone @drop.prevent="selectedFile" @change="selectedFile" />

                <div id="table-product-list-all" class="table-card gridjs-border-none table-responsive mt-1">
                  <table class="table align-middle table-nowrap mb-0">
                    <thead class="table-primary">
                      <tr class="text">
                        <th></th>
                        <th>Tamanho</th>
                        <th>Tipo</th>
                        <th>Criada em</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody class="list form-check-all">
                      <tr class="gridjs-tr" v-for="(photo, index) of product.photos" :key="index">
                        <td class="gridjs-td">
                          <span>
                            <div class="d-flex align-items-center">
                              <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm bg-light rounded p-1">
                                  <img :src="photo.path" :alt="photo.name" class="img-fluid d-block" />
                                </div>
                              </div>
                              <div class="flex-grow-1">
                                <p class="fs-14 mb-1">{{ photo.name }}</p>
                              </div>
                            </div>
                          </span>
                        </td>
                        <td class="gridjs-td">{{ formatBytes(photo.size) }}</td>
                        <td class="gridjs-td">{{ photo.mimetype }}</td>
                        <td class="gridjs-td">{{ translateDateOnly(photo.created_at) }} <small class="text-muted">{{ translateTimeOnly(photo.created_at) }}</small></td>
                        <td class="gridjs-td"><button class="btn btn-sm btn-danger" @click.prevent="deletePhoto(photo.id)">Apagar</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>

          <!--div class="card">
            <div class="card-header">
              <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" data-bs-toggle="tab" href="#addproduct-general-info" role="tab">
                    Informações Gerais
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#addproduct-metadata" role="tab">
                    Metadados
                  </a>
                </li>
              </ul>
            </div>

            <div class="card-body">
              <div class="tab-content">
                <div class="tab-pane active" id="addproduct-general-info" role="tabpanel">
                  <div class="mb-3">
                    <label class="form-label" for="manufacturer-name-input">Nome do Fabricante</label>
                    <input type="text" class="form-control" id="manufacturer-name-input"
                      placeholder="Enter manufacturer name" />
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="mb-3">
                        <label class="form-label" for="manufacturer-brand-input">Empresa</label>
                        <input type="text" class="form-control" id="manufacturer-brand-input"
                          placeholder="Enter manufacturer brand" />
                      </div>
                    </div>

                    <div class="col-lg-8">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="mb-3">
                            <label class="form-label" for="product-discount-input">Desconto</label>
                            <div class="input-group mb-3">
                              <span class="input-group-text" id="product-discount-addon">%</span>
                              <input type="text" class="form-control" id="product-discount-input"
                                placeholder="Enter discount" aria-label="discount"
                                aria-describedby="product-discount-addon" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tab-pane" id="addproduct-metadata" role="tabpanel">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label" for="meta-title-input">Meta Título</label>
                        <input type="text" class="form-control" placeholder="Digite meta título" id="meta-title-input" />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label" for="meta-keywords-input">Meta Keywords</label>
                        <input type="text" class="form-control" placeholder="Digite meta keywords"
                          id="meta-keywords-input" />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label class="form-label" for="meta-description-input">Meta Descrição</label>
                    <textarea class="form-control" id="meta-description-input" placeholder="Digite meta descrição"
                      rows="3"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div-->
          
          <div class="hstack text-end mb-3 justify-content-end gap-2">
            <b-button variant="outline-dark" class="waves-effect waves-light" @click.prevent="goBack">Cancelar</b-button>
            <button type="button" class="btn btn-success w-sm" @click.prevent="saveProduct">Salvar</button>
          </div>
        </form>
      </div>

      <!--div class="col-lg-4">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Publicação</h5>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="choices-publish-status-input" class="form-label">Status</label>
              <Multiselect class="form-control" v-model="value1" :close-on-select="true" :searchable="true"
                :create-option="true" :options="[
                  { value: 'Published', label: 'Publicado' },
                  { value: 'Scheduled', label: 'Agendado' },
                  { value: 'Draft', label: 'Rascunho' },
                ]" />
            </div>

            <div>
              <label for="choices-publish-visibility-input" class="form-label">Visibilidade</label>
              <Multiselect class="form-control" v-model="value2" :close-on-select="true" :searchable="true"
                :create-option="true" :options="[
                  { value: 'Public', label: 'Público' },
                  { value: 'Hidden', label: 'Não Visível' },
                ]" />
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Agendamento da Publicação</h5>
          </div>
          <div class="card-body">
            <div>
              <label for="datepicker-publish-input" class="form-label">Data e Hora</label>

              <flat-pickr v-model="date1" :config="datetimeConfig" placeholder="Enter publish date"
                class="form-control"></flat-pickr>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Categorias de Produto</h5>
          </div>
          <div class="card-body">
            <p class="text-muted mb-2">
              <a href="#" class="float-end text-decoration-underline">Adicionar</a>Selecione a Categoria do Produto
            </p>

            <Multiselect class="form-control" v-model="value3" :close-on-select="true" :searchable="true"
              :create-option="true" :options="[
                { value: 'All', label: 'Todos' },
              ]" />
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Etiquetas do Produto</h5>
          </div>
          <div class="card-body">
            <div class="hstack gap-3 align-items-start">
              <div class="flex-grow-1">
                <input class="form-control" data-choices data-choices-multiple-remove="true" placeholder=""
                  type="text" value="Cotton" />
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Descrição curta do Produto</h5>
          </div>
          <div class="card-body">
            <p class="text-muted mb-2">Adicione uma descrição curta ao Produto</p>
            <textarea class="form-control" placeholder="Mínimo de 100 characters" rows="3"></textarea>
          </div>
        </div>
      </div-->
    </div>
    
  </Layout>
</template>
